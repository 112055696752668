import React, {useCallback, useEffect, useState} from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import 'react-tabs/style/react-tabs.css';
import liveAuctionData from '../assets/fake-data/data-live-auction';
import LiveAuction from '../components/layouts/LiveAuction';
import {Link} from "react-router-dom";


const PaySuccess = () => {

    useEffect(() => {
        //解析url 参数
        // let url = window.location.href;
        // let id = url.split("?")[1].split("=")[1];

        // productDetail(id).then(res => {
        //     if (res.data) {
        //         setData(res.data)
        //     }
        // })

    }, [])

    return (
        <div className='item-details'>
            <Header />

            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center" style={{color: "#4ead4e"}}>
                                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                                </h1>
                                <h1 className="heading text-center">
                                    Thank You</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li>Payment is successfully processed and your order is on the way</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*<div className="tf-section tf-item-details">*/}
            {/*    <div className="themesflat-container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-md-12">*/}
            {/*                <div className="success-text">*/}
            {/*                    <i className="fa fa-check-circle" aria-hidden="true"></i>*/}
            {/*                    <h2>Thank You</h2>*/}
            {/*                    <p>Payment is successfully processed and your order is on the way</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <LiveAuction data={liveAuctionData} />
            <Footer />
        </div>
    );
}

export default PaySuccess;
