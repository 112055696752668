import React, {useState, Fragment, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CardModal from '../CardModal';

import {search, settingInfo} from "../../../api/api.ts";

const TodayPicks = () => {
    const [dataTab] = useState(
        [
            {
                id: 1,
                title: "All",
            },
            {
                id: 2,
                title: "Art",
            },
            {
                id: 3,
                title: "Music",
            },
            {
                id: 4,
                title: "Collectibles",
            },
            {
                id: 5,
                title: "Sports",
            },
        ]
    )


    const [data, setData] = useState([]);

    const getFormattedDate = () => {
        const date = new Date(); // 获取当前日期和时间
        const year = date.getFullYear(); // 获取年份
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 获取月份，+1因为月份从0开始计算，padStart保证两位数
        const day = date.getDate().toString().padStart(2, '0'); // 获取日，padStart保证两位数

        return `${year}-${month}-${day}`; // 格式化为YYYY-MM-DD
    }

    const navigate = useNavigate();

    useEffect(() => {
        //解析url 参数
        // let url = window.location.href;
        // let id = url.split("?")[1].split("=")[1];

        let url = window.location.href;
        let experienceName = url.split("?")?.[1]?.split("=")[1];
        settingInfo().then(setting => {
            let settingData = setting.data
            search({
                type: 2,
                countryCode: 'ty',
                startDate: getFormattedDate(),
                experienceName: experienceName || ''
            }).then(res => {
                if (res.data && res.data.length > 0) {
                    setData(res.data)
                    setDataPanel([{
                        id: 1,
                        dataContent: res.data.map(item => {
                            return {
                                id: item.id,
                                img: "https://monster.tybblog.cn/backend-service/"+item.logo + "?x-oss-process=image/resize,h_200/quality,q_90",
                                title: item.translations.name,
                                imgAuthor: "https://monster.tybblog.cn/backend-service/"+ (item?.org?.logo || settingData?.logoFullUrl) + "?x-oss-process=image/resize,w_100,h_100/quality,q_90",
                                nameAuthor: item?.org?.translations?.name || settingData?.translations?.name,
                                price: ('$'+item?.minPrice) || 'Free',
                                priceChange: "$12.246",
                                wishlist: "100",
                                imgCollection: item.collected,
                                nameCollection: "Creative Art 3D"
                            }
                        })
                    }])
                } else {
                    navigate('/no-result');
                }
            })
        })
    }, [])

    const [dataPanel, setDataPanel] = useState(
        []
    )

    const [visible , setVisible] = useState(8);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 4);
    }

    const [modalShow, setModalShow] = useState(false);

    return (
        <Fragment>
            <div className="tf-section sc-explore-2">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="seclect-box style3">
                                <div id="artworks" className="dropdown">
                                    <Link to="#" className="btn-selector nolink">All Artworks</Link>
                                    <ul>
                                        <li><span>Abstraction</span></li>
                                        <li className="active"><span>Skecthify</span></li>
                                        <li><span>Patternlicious</span></li>
                                        <li><span>Virtuland</span></li>
                                        <li><span>Papercut</span></li>
                                    </ul>
                                </div>
                                <div id="sort-by" className="dropdown style-2">
                                    <Link to="#" className="btn-selector nolink">Sort by</Link>
                                    <ul>
                                        <li><span>Top rate</span></li>
                                        <li className="active"><span>Mid rate</span></li>
                                        <li><span>Low rate</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flat-tabs explore-tab">
                                <Tabs >
                                    <TabList>
                                        {
                                            dataTab.map(data=> (
                                                <Tab key={data.id} >{data.title}</Tab>
                                            ))
                                        }
                                    </TabList>
                                    {
                                        dataPanel.map(data =>(
                                            <TabPanel key={data.id}>
                                                {

                                                    data.dataContent.slice(0,visible).map (item=>(
                                                    <div key={item.id} className={`sc-card-product explode style2 mg-bt ${item.feature ? 'comingsoon' : '' } `}>
                                                        <div className="card-media">
                                                            <Link to={"/item-details-01?id=" + item.id}><img src={item.img} alt="Axies" /></Link>
                                                            <div className="button-place-bid">
                                                                <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3"><span>Place Bid</span></button>
                                                            </div>
                                                            <Link to="/login" className="wishlist-button heart"><span className="number-like">{item.wishlist}</span></Link>
                                                            <div className="coming-soon">{item.feature}</div>
                                                        </div>
                                                        <div className="card-title">
                                                            <h5><Link to={"/item-details-01?id="+item.id}>{item.title}</Link></h5>

                                                        </div>
                                                        <div className="meta-info">
                                                            <div className="author">
                                                                <div className="avatar">
                                                                    <img src={item.imgAuthor} alt="Axies" />
                                                                </div>
                                                                <div className="info">
                                                                    <span>Creator</span>
                                                                    <h6> <Link to="/authors-02">{item.nameAuthor}</Link> </h6>
                                                                </div>
                                                            </div>
                                                            <div className="price">
                                                                <span>Current Bid</span>
                                                                <div className="price-details">
                                                                    <h5>{item.price}</h5>
                                                                    {/*<span>= {item.priceChange}</span>*/}
                                                                </div>
                                                            </div>
                                                            {/*<div className="tags">{item.tags}</div>*/}
                                                        </div>
                                                        {/*<div className="card-bottom style-explode">*/}
                                                        {/*    /!*<Link to="/activity-01" className="view-history reload">View History</Link>*!/*/}
                                                        {/*</div>*/}
                                                    </div>
                                                    ))
                                                }
                                                {
                                                    visible < data.dataContent.length &&
                                                    <div className="col-md-12 wrap-inner load-more text-center">
                                                        <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Load More</span></Link>
                                                    </div>
                                                }
                                            </TabPanel>
                                        ))
                                    }
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Fragment>
    );
}

export default TodayPicks;
