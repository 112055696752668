const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/'
    },
    {
        id: 2,
        name: 'Explore',
        links: '/explore-02',
    },
    {
        id: 3,
        name: 'Activity',
        links: '/explore-02',
    },
    {
        id: 5,
        name: 'Community',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'Blog',
                links: '/blog'
            },
            {
                id: 2,
                    sub: 'FAQ',
                links: '/faq'
            },
            {
                id: 3,
                sub: 'Help Center',
                links: '/help-center'
            }
        ],
    },
    {
        id: 7,
        name: 'Contact',
        links: '/contact-01',
        namesub: [
            {
                id: 1,
                sub: 'Contact 1',
                links: '/contact-01'
            },
            {
                id: 2,
                sub: 'Contact 2',
                links: '/contact-02'
            }
        ],
    },

]

export default menus;
