import React, {useCallback, useEffect, useState} from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Link } from 'react-router-dom';
import Countdown from "react-countdown";
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import liveAuctionData from '../assets/fake-data/data-live-auction';
import LiveAuction from '../components/layouts/LiveAuction';
import img1 from '../assets/images/avatar/avt-3.jpg'
import {creatOrderInfo, productDetail, queryBooking, stripePaymentMethod} from "../api/api.ts";

const ItemDetails01 = () => {
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        //解析url 参数
        let url = window.location.href;
        let id = url.split("?")[1].split("=")[1];

        queryBooking(id).then(res => {
            if (res.data) {
                setData(res.data)
            }
        })

    }, [])

    const orderStatus = (orderStatus) => {
        switch (orderStatus) {
            case 0: return 'New';
            case 1: return 'Completed';
            case 6: return 'Expired';
            case 9: return 'Cancelled';
        }
    }

    const [options, setOptions] = useState({});

    const hideShow = () => {
        setShow(false)
    }

    const getFormattedDate = () => {
        const date = new Date(); // 获取当前日期和时间
        const year = date.getFullYear(); // 获取年份
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 获取月份，+1因为月份从0开始计算，padStart保证两位数
        const day = date.getDate().toString().padStart(2, '0'); // 获取日，padStart保证两位数

        return `${year}-${month}-${day}`; // 格式化为YYYY-MM-DD
    }

    return (
        <div className='item-details'>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Order Details</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Explore</Link></li>
                                    <li>Order Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-section tf-item-details">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="content-left">
                                <div className="media">
                                    <img src={data?.supplierInfo?.attachments ? "https://monster.tybblog.cn/backend-service/"+data?.supplierInfo?.attachments[0].url : ''} alt="Axies" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="content-right">
                                <div className="sc-item-details">
                                    <h2 className="style2">{data?.supplierInfo?.translations?.name}</h2>
                                    {/*<div className="meta-item">*/}
                                    {/*    <div className="left">*/}
                                    {/*        <span className="viewed eye">225</span>*/}
                                    {/*        <span to="/login" className="liked heart wishlist-button mg-l-8"><span className="number-like">100</span></span>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="right">*/}
                                    {/*        <Link to="#" className="share"></Link>*/}
                                    {/*        <Link to="#" className="option"></Link>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="client-infor sc-card-product">*/}
                                    {/*    <div className="meta-info">*/}
                                    {/*        <div className="author">*/}
                                    {/*            <div className="avatar">*/}
                                    {/*                <img src={"https://monster.tybblog.cn/backend-service/"+data?.supplierInfo?.logo} alt="Axies" />*/}
                                    {/*            </div>*/}
                                    {/*            <div className="info">*/}
                                    {/*                <span>Owned By</span>*/}
                                    {/*                <h6> <Link to="/author-02">{data?.supplierInfo?.meetingPoint}</Link> </h6>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="meta-info">*/}
                                    {/*        <div className="author">*/}
                                    {/*            <div className="avatar">*/}
                                    {/*                <img src={"https://monster.tybblog.cn/backend-service/"+data?.supplierInfo?.logo} alt="Axies" />*/}
                                    {/*            </div>*/}
                                    {/*            <div className="info">*/}
                                    {/*                <span>Create By</span>*/}
                                    {/*                <h6> <Link to="/author-02">{data?.supplierInfo?.meetingPoint}</Link> </h6>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}

                                    {/*</div>*/}
                                    <p></p>

                                    <div className="meta-item-details style2" style={{"marginBottom": "18px"}}>
                                        <div className="item meta-price" style={{"width": "100%"}}>
                                            <span className="heading">Order Number</span>
                                            <div className="price">
                                                <div className="price-box">
                                                    <h5> {data?.orderNumber}</h5>
                                                    {/*<span>= $12.246</span>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="meta-item-details style2" style={{"marginBottom": "18px"}}>
                                        <div className="item meta-price">
                                            <span className="heading">Order Status</span>
                                            <div className="price">
                                                <div className="price-box">
                                                    <h5> {orderStatus(data?.orderStatus)}</h5>
                                                    {/*<span>= $12.246</span>*/}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item count-down">
                                            <span className="heading style-2">Code</span>
                                            <div>
                                                <h5> {data?.voucherCode}</h5>
                                                {/*<span>= $12.246</span>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="meta-item-details style2">
                                        <div className="item meta-price">
                                            <span className="heading">Total Price</span>
                                            <div className="price">
                                                <div className="price-box">
                                                    <h5> {data?.totalPrice ?  ('$' + data?.totalPrice): ''}</h5>
                                                    {/*<span>= $12.246</span>*/}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item count-down">
                                            <span className="heading style-2">Purchase time</span>
                                            <div>
                                                <h6> {new Date(data?.createTime).toLocaleString('en-US', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit',
                                                    hour12: false
                                                })}</h6>
                                                {/*<span>= $12.246</span>*/}
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="sc-button loadmore style bag fl-button pri-3"><span>Place a bid</span></div>*/}
                                    <div className="flat-tabs themesflat-tabs">
                                    <Tabs>
                                        <TabList>
                                        {/*<Tab>Bid History</Tab>*/}
                                            <Tab>Provenance</Tab>
                                        <Tab>Info</Tab>
                                        </TabList>

                                        {/*<TabPanel>*/}
                                        {/*    <ul className="bid-history-list">*/}
                                        {/*    {*/}
                                        {/*        dataHistory.map((item, index) => (*/}
                                        {/*            <li key={index} item={item}>*/}
                                        {/*                <div className="content">*/}
                                        {/*                    <div className="client">*/}
                                        {/*                        <div className="sc-author-box style-2">*/}
                                        {/*                            <div className="author-avatar">*/}
                                        {/*                                <Link to="#">*/}
                                        {/*                                    <img src={item.img} alt="Axies" className="avatar" />*/}
                                        {/*                                </Link>*/}
                                        {/*                                <div className="badge"></div>*/}
                                        {/*                            </div>*/}
                                        {/*                            <div className="author-infor">*/}
                                        {/*                                <div className="name">*/}
                                        {/*                                    <h6><Link to="/author-02">{item.name} </Link></h6> <span> place a bid</span>*/}
                                        {/*                                </div>*/}
                                        {/*                                <span className="time">{item.time}</span>*/}
                                        {/*                            </div>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="price">*/}
                                        {/*                        <h5>{item.price}</h5>*/}
                                        {/*                        <span>= {item.priceChange}</span>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </li>*/}
                                        {/*        ))*/}
                                        {/*    }*/}
                                        {/*    </ul>*/}
                                        {/*</TabPanel>*/}
                                        <TabPanel>
                                            <div className="provenance">
                                                <div dangerouslySetInnerHTML={{ __html: data?.supplierInfo?.translations?.description }}></div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <ul className="bid-history-list">
                                                    <li>
                                                        <div className="content">
                                                            <div className="client">
                                                                <div className="sc-author-box style-2">
                                                                    <div className="author-avatar">
                                                                        <Link to="#">
                                                                            <img src={img1} alt="Axies" className="avatar" />
                                                                        </Link>
                                                                        <div className="badge"></div>
                                                                    </div>
                                                                    <div className="author-infor">
                                                                        <div className="name">
                                                                            <h6> <Link to="/author-02">Mason Woodward </Link></h6> <span> place a bid</span>
                                                                        </div>
                                                                        <span className="time">8 hours ago</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                            </ul>
                                        </TabPanel>
                                    </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LiveAuction data={liveAuctionData} />
            <Footer />
        </div>
    );
}

export default ItemDetails01;
