import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import {orderList, profile} from "../api/api.ts";


const Activity01 = () => {
    const [dataBox, setDataBox] = useState(
        []);
    const [dataFilter] = useState(
        [
            {
                icon: 'fa fa-book',
                name: 'Listings'
            },
            {
                icon: 'icon-fl-heart-filled',
                name: 'Like'
            },
            {
                icon: 'icon-fl-buy',
                name: 'Purchases'
            },
            {
                icon: 'icon-fl-discount',
                name: 'Sales'
            },
            {
                icon: 'icon-fl-logout',
                name: 'Transfer'
            },
            {
                icon: 'icon-fl-star',
                name: 'Burns'
            },
            {
                icon: 'icon-fl-credit-card',
                name: 'Bids'
            },
            {
                icon: 'fa fa-book',
                name: 'Followings'
            },
        ]
    )

    const [visible , setVisible] = useState(5);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 5);
    }

    const orderStatus = (orderStatus) => {
        switch (orderStatus) {
            case 0: return 'New';
            case 1: return 'Completed';
            case 6: return 'Expired';
            case 9: return 'Cancelled';
        }
    }

    useEffect(() => {
        orderList({
            pageIndex: 1,
            pageSize: 999,
            tyStatus: 0
        }).then(res => {
            setDataBox(res.data.map(item => {
                return {
                    id: item.id,
                    img: "https://monster.tybblog.cn/backend-service/" + item?.supplierInfo?.attachments?.[0]?.url,
                    title: item?.orderDetails?.[0]?.siteName,
                    status: 'Order Status' ,
                    author: orderStatus(item.orderStatus),
                    time: new Date(item?.createTime).toLocaleString('en-US'),
                    icon: 'fa fa-book'
                }
            }))
        })
    }, [])

    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">My Orders</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Orders</Link></li>
                                    <li>My Orders</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-activity s1 tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-12">
                            {
                                dataBox.slice(0,visible).map((item,index) =>(
                                    <div className="sc-card-activity style1" key={index}>
                                        <div className="content">
                                            <div className="media">
                                                <img src={item.img} alt="Axies" />
                                            </div>
                                            <div className="infor">
                                                <h3> <Link to="/item-details-01">{item.title}</Link></h3>
                                                <div className="status">{item.status} <span className="author">{item.author}</span></div>
                                                <div className="time">{item.time}</div>
                                            </div>
                                        </div>
                                        <Link to={"/order-details-01?id="+item.id}><div className={`button-active icon`}><i className={ item.icon}></i></div></Link>

                                    </div>
                                ))
                            }
                            {
                                visible < dataBox.length &&
                                <div className="col-md-12 wrap-inner load-more text-center">
                                    <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3 mt-10" onClick={showMoreItems}><span>Load More</span></Link>
                                </div>
                            }
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">

                            <div id="side-bar" className="side-bar style-2">

                                <div className="widget widget-search mgbt-24">
                                    <form action="#">
                                        <input type="text" placeholder="Enter your word art" required />
                                        <button><i className="icon-fl-search-filled"></i></button>
                                    </form>
                                </div>

                                <div className="widget widget-filter style-2 mgbt-0">
                                    <h3 className="title-widget">Filter</h3>
                                    <ul className="box-check">
                                        {
                                            dataFilter.map((item,index) => (
                                                <li key={index}><Link to="#" className="box-widget-filter"><i className={item.icon}></i>{item.name}</Link></li>
                                            ))
                                        }

                                    </ul>
                                    <Link to="#" className="clear-check btn-filter style-2">
                                        Clear All Filters
                                    </Link>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Activity01;
