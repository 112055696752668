import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import avt from '../assets/images/avatar/avata_profile.jpg'
import bg1 from '../assets/images/backgroup-secsion/option1_bg_profile.jpg'
import bg2 from '../assets/images/backgroup-secsion/option2_bg_profile.jpg'
import { profile, updateProfile } from "../api/api.ts";
import { Toast, ToastContainer } from 'react-bootstrap';

const EditProfile = () => {
    

    const [show, setShow] = useState(false);
    const [bgState, setBgState] = useState('success')
    const [tip, setTip] = useState('');
    const closeShow = () => setShow(false);

    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        address: '',
        zipCode: ''
    });

    const [user, setUser] = useState(null);
    useEffect(() => {
        let token = localStorage.getItem('token');
        if (token) {
            const item = localStorage.getItem('user');
            if (item) {
                setForm(JSON.parse(item))
            } else {
                profile().then(res => {
                    localStorage.setItem('user', JSON.stringify(res.data))
                    setForm(res.data)
                })
            }
        }
    }, [])

    const handleChange = (event) => {
        // 创建新的状态对象，并更新改变的字段
        setForm(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        updateProfile(form).then( res => {
            if(res.code == 200){
                setTip(res.msg)
                setBgState('success')
            }else{
                setTip(res.msg)
                setBgState('danger')
            }
            setShow(true)
        })
    }
 

    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Edit Profile</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Pages</Link></li>
                                    <li>Edit Profile</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-create-item tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        {/* <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                            <div className="sc-card-profile text-center">
                                <div className="card-media">
                                    <img id="profileimg" src={avt} alt="Axies" />                         
                                </div>
                            <div id="upload-profile">
                                <Link to="#" className="btn-upload">
                                    Upload New Photo </Link>
                                    <input id="tf-upload-img" type="file" name="profile" required="" />
                            </div>
                            <Link to="#" className="btn-upload style2">
                                Delete</Link>
                            </div>
                         </div> */}
                        <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                            <div className="form-upload-profile">
                                {/* <h4 className="title-create-item">Choice your Cover image</h4>
                                <div className="option-profile clearfix">
                                    <form action="#">
                                        <label className="uploadFile">
                                            <input type="file" className="inputfile form-control" name="file" />
                                        </label>
                                    </form>
                                    <div className="image">
                                        <img src={bg1} alt="Axies" />
                                    </div>
                                    <div className="image style2">
                                        <img src={bg2} alt="Axies" />
                                    </div>
                                </div> */}
                                <form action="#" className="form-profile">

                                    <div className="form-infor-profile">
                                        <div className="info-account">
                                            <h4 className="title-create-item">Account info</h4>
                                            <fieldset>
                                                <h4 className="title-infor-account">First Name</h4>
                                                <input name="firstName" type="text" value={form.firstName}
                                                    onChange={handleChange} placeholder="First Name" required />
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Last Name</h4>
                                                <input name="lastName" type="text" value={form.lastName}
                                                    onChange={handleChange} placeholder="Last Name" required />
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Phone</h4>
                                                <input name="phone" type="text" value={form.phone}
                                                    onChange={handleChange} placeholder="Phone" required />
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Email</h4>
                                                <input name="email" type="email" value={form.email}
                                                    onChange={handleChange} placeholder="Enter your email" required />
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Address</h4>
                                                <input name="address" type="text" value={form.address}
                                                    onChange={handleChange} placeholder="Address" required />
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Postal Code</h4>
                                                <input name="zipCode" type="text" value={form.zipCode}
                                                    onChange={handleChange} placeholder="Postal Code" required />
                                            </fieldset>
                                        </div>
                                        {/* <div className="info-social">
                                            <h4 className="title-create-item">Your Social media</h4>
                                            <fieldset>
                                                <h4 className="title-infor-account">Facebook</h4>
                                                <input type="text" placeholder="Facebook username" required />
                                                <Link to="#" className="connect"><i className="fab fa-facebook"></i>Connect to face book</Link>
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Twitter</h4>
                                                <input type="text" placeholder="Twitter username" required />
                                                <Link to="#" className="connect"><i className="fab fa-twitter"></i>Connect to Twitter</Link>
                                            </fieldset>
                                            <fieldset>
                                                <h4 className="title-infor-account">Discord</h4>
                                                <input type="text" placeholder="Discord username" required />
                                                <Link to="#" className="connect"><i className="icon-fl-vt"></i>Connect to Discord</Link>
                                            </fieldset>
                                        </div> */}
                                    </div>
                                    <button className="tf-button-submit mg-t-15" onClick={ handleSubmit }>
                                        Update Profile
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

            <ToastContainer position="top-end" className="p-3 position-fixed" style={{ zIndex: 999 }}>
                <Toast show={show} onClose={closeShow} bg={ bgState } delay={3000} autohide>
                    <Toast.Header>
                        <h4 className="me-auto">Notification</h4>
                    </Toast.Header>
                    <Toast.Body>
                        <h5>{tip}</h5>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
}

export default EditProfile;
