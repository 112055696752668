import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import img1 from '../assets/images/box-item/icon1-recont-post.jpg'
import img2 from '../assets/images/box-item/icon2-recont-post.jpg'
import img3 from '../assets/images/box-item/icon3-recont-post.jpg'
import img4 from '../assets/images/box-item/icon4-recont-post.jpg'
import imgblog1 from '../assets/images/blog/thumb-7.jpg'
import imgblog2 from '../assets/images/blog/thumb-6.jpg'
import imgblogdetail1 from '../assets/images/blog/thumb1_details.jpg'
import imgblogdetail2 from '../assets/images/blog/thumb2_details.jpg'
import {cmsDetails, cmsList} from "../api/api.ts";

const BlogDetails = () => {
    const [dataRecent, setDataRecent] = useState(
        [
        ]
    )
    const [dataTags] = useState(
        [
            {
                name: 'Bitcoin'
            },
            {
                name: 'NFT'
            },
            {
                name: 'Bids'
            },
            {
                name: 'Digital'
            },
            {
                name: 'Arts'
            },
            {
                name: 'Marketplace'
            },
            {
                name: 'Token'
            },
            {
                name: 'Wallet'
            },
            {
                name: 'Crypto'
            },
        ]
    )

    const [data, setData] = useState({});

    useEffect(() => {
        //解析url 参数
        let url = window.location.href;
        let id = url.split("?")[1].split("=")[1];

        cmsDetails(id).then(res => {
            if (res.data) {
                setData(res.data)
            }
        })

        cmsList({}).then(res => {
            let cmsData = []
            res.data.map(item => {
                if (item.cms) {
                    cmsData.push({
                        text : 'Lorem ipsum dolor sit amer....',
                        id: item.cms.id,
                        img: "https://monster.tybblog.cn/backend-service/"+ item.cms?.thumbnailUrl + "?x-oss-process=image/resize,h_200/quality,q_90",
                        title: item.cms?.translations?.title,
                        time: new Date(item.cms?.createTime).toLocaleString('en-US', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric'
                        })
                    })
                }
            })
            setDataRecent(cmsData)
        })

    }, [])

    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Blog Details</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Community</Link></li>
                                    <li>Blog Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-section post-details">
                <div className="themesflat-container">
                    <div className="wrap-flex-box style">
                        <div className="post">
                            <div className="inner-content">
                                <h2 className="title-post">{data?.translations?.title}</h2>
                                <div className="divider"></div>
                                <div className="meta-post flex mg-bt-31">
                                    <div className="box">
                                        <div className="inner">
                                            {/*<h6 className="desc">DESIGNER INTERVIEW</h6>*/}
                                            {/*<p>AUGUST CHAPTER</p>*/}
                                        </div>
                                    </div>
                                    <div className="box left">
                                        <div className="inner boder pad-r-50">
                                            <h6 className="desc">WRITER</h6>
                                            <p>{data?.translations?.author}</p>
                                        </div>
                                        <div className="inner mg-l-39 mg-r-1">
                                            <h6 className="desc">DATE</h6>
                                            <p>{
                                                new Date(data?.updateTime).toLocaleString('en-US', {
                                                    day: '2-digit',
                                                    month: 'short',
                                                    year: 'numeric'
                                                })
                                            }</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="image">
                                    <img src={"https://monster.tybblog.cn/backend-service/"+ data?.thumbnailUrl} alt="Axies" />
                                </div>
                                <div className="inner-post mg-t-40" dangerouslySetInnerHTML={{ __html: data?.translations?.content}}>

                                </div>
                                <div className="sc-widget style-1">
                                    <div className="widget widget-tag style-2">
                                        <h4 className="title-widget">Tags</h4>
                                        <ul>
                                            <li><Link to="#">Bitcoin</Link></li>
                                            <li><Link to="#">Token</Link></li>
                                            <li><Link to="#">Wallet</Link></li>
                                        </ul>
                                    </div>
                                    <div className="widget widget-social style-2">
                                        <h4 className="title-widget">Share:</h4>
                                        <ul>
                                            <li><Link to="#" className="icon-fl-facebook"></Link></li>
                                            <li className="style-2"><Link to="#" className="icon-fl-coolicon"></Link></li>
                                            <li className="mgr-none"><Link to="#" className="icon-fl-mess"></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="divider d2"></div>
                                <div id="comments">
                                    <h3 className="heading mg-bt-23">
                                        Leave A Comment
                                    </h3>
                                    <form action="#" method="post" id="commentform" className="comment-form" >
                                        <fieldset className="name">
                                            <input type="text" id="name" placeholder="Name" className="tb-my-input" name="name" tabIndex="2" aria-required="true" required />
                                        </fieldset>
                                        <fieldset className="email">
                                            <input type="email" id="email" placeholder="Email *" className="tb-my-input" name="email" tabIndex="2" aria-required="true" required />
                                        </fieldset>
                                        <fieldset className="message">
                                            <textarea id="message" name="message" rows="4" placeholder="Message" tabIndex="4" aria-required="true" required />
                                        </fieldset>
                                        <div className="btn-submit mg-t-36">
                                            <button className="tf-button-submit" type="submit">
                                                Send comment
                                        </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="side-bar details">
                            <div className="widget widget-recent-post mg-bt-43">
                                <h3 className="title-widget mg-bt-23">Recent Post</h3>
                                <ul>
                                    {
                                        dataRecent.map((item,index) => (
                                            <li key={index} className="box-recent-post">
                                                <div className="box-feature"><Link to={"/blog-details?id="+item?.id}><img src={item.img} alt="Axies" /></Link></div>
                                                <div className="box-content">
                                                    <Link to={"/blog-details?id="+item?.id} className="title-recent-post">{item.title}</Link>
                                                    <span><span className="sub-recent-post">{item.text}</span><Link to="/blog" className="day-recent-post">{item.time}</Link></span>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className="widget widget-tag style-1">
                                <h3 className="title-widget mg-bt-23">Popular Tag</h3>
                                <ul>
                                    {
                                        dataTags.map((item,index) => (
                                            <li key={index}><Link to="/blog" className="box-widget-tag">{item.name}</Link></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default BlogDetails;
