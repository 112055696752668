import imgAuthor1 from '../images/image/avt-1.71a23be871d509a23d2d.jpg'
import img1left from '../images/image/collection-item-2.27af7fa2d4951793d35a.jpg'
import img1right1 from '../images/image/collection-item-top-1.a9580c5f5e1b11743d1d.jpg'
import img1right2 from '../images/image/下载.jpg'
import img1right3 from '../images/image/collection-item-bottom-4.b5c01968dab20195c2f4.jpg'

import imgAuthor2 from '../images/image/avt-7.98fafb24331353eb43c0.jpg'
import img2left from '../images/image/img-collection23.adb6ba7843edc98fc4b5.jpg'
import img2right1 from '../images/image/img-collection24.49fa677b102b9bc96784.jpg'
import img2right2 from '../images/image/img-collection25.31b755db7327e519d3d5.jpg'
import img2right3 from '../images/image/img-collection11.91ff283f43a3be86b43c.jpg'

import imgAuthor3 from '../images/image/avt-8.5196d4a4839fce8a5b96.jpg'
import img3left from '../images/image/img-collection18.5806892d40ebdf0bbefe.jpg'
import img3right1 from '../images/image/下载 (1).jpg'
import img3right2 from '../images/image/img-collection18.5806892d40ebdf0bbefe.jpg'
import img3right3 from '../images/image/img-collection17.1c61e443a5c096bb57f7.jpg'

import img1right4 from '../images/box-item/img-collection8.jpg'
import img2right4 from '../images/box-item/img-collection1.jpg'
import imgtop1 from '../images/box-item/collection-item-4.jpg'
import imgtop2 from '../images/box-item/collection-item-5.jpg'
import imgtop3 from '../images/box-item/collection-item-1.png'
import img4left from '../images/box-item/image-box-15.jpg'
import img4right1 from '../images/box-item/image-box-18.jpg'
import img4right2 from '../images/box-item/image-box-14.jpg'
import img4right3 from '../images/box-item/img-collection13.jpg'
import img5left from '../images/box-item/img-collection14.jpg'
import img5right1 from '../images/box-item/img-collection15.jpg'
import img5right2 from '../images/box-item/img-collection16.jpg'
import img5right3 from '../images/box-item/img-collection19.jpg'
import img6left from '../images/box-item/image-box-17.jpg'
import img6right1 from '../images/box-item/img-collection22.jpg'
import img6right2 from '../images/box-item/img-collection21.jpg'
import img6right3 from '../images/box-item/img-collection20.jpg'






const popularCollectionData = [
    {
        title: "Creative Art Collection",
        imgAuthor: imgAuthor1,
        name: "Ralph Garraway",
        imgleft: img1left,
        imgright1: img1right1,
        imgright2: img1right2,
        imgright3: img1right3,
        imgright4: img1right4,
        imgtop: imgtop1,
        wishlist: "100",
        count: '12 item products'
    },
    {
        title: "Colorful Abstract",
        imgAuthor: imgAuthor2,
        name: "Mason Woodward",
        imgleft: img2left,
        imgright1: img2right1,
        imgright2: img2right2,
        imgright3: img2right3,
        imgright4: img1right4,
        imgtop: imgtop2,
        wishlist: "220",
        count: '26 item products'
    },
    {
        title: "Modern Art Collection",
        imgAuthor: imgAuthor3,
        name: "Freddie Carpenter",
        imgleft: img3left,
        imgright1: img3right1,
        imgright2: img3right2,
        imgright3: img3right3,
        imgright4: img2right4,
        imgtop: imgtop3,
        wishlist: "90",
        count: '26 item products'
    },
    {
        title: "Creative Art Collection",
        imgAuthor: imgAuthor1,
        name: "Ralph Garraway",
        imgleft: img4left,
        imgright1: img4right1,
        imgright2: img4right2,
        imgright3: img4right3,
        imgright4: img1right4,
        imgtop: imgtop1,
        wishlist: "145",
        count: '12 item products'
    },
    {
        title: "Colorful Abstract",
        imgAuthor: imgAuthor2,
        name: "Mason Woodward",
        imgleft: img5left,
        imgright1: img5right1,
        imgright2: img5right2,
        imgright3: img5right3,
        imgright4: img1right4,
        imgtop: imgtop2,
        wishlist: "100",
        count: '26 item products'
    },
    {
        title: "Modern Art Collection",
        imgAuthor: imgAuthor3,
        name: "Freddie Carpenter",
        imgleft: img6left,
        imgright1: img6right1,
        imgright2: img6right2,
        imgright3: img6right3,
        imgright4: img2right4,
        imgtop: imgtop3,
        wishlist: "220",
        count: '26 item products'
    },
    {
        title: "Creative Art Collection",
        imgAuthor: imgAuthor1,
        name: "Ralph Garraway",
        imgleft: img1left,
        imgright1: img1right1,
        imgright2: img1right2,
        imgright3: img1right3,
        imgright4: img1right4,
        imgtop: imgtop1,
        wishlist: "100",
        count: '12 item products'
    },
    {
        title: "Colorful Abstract",
        imgAuthor: imgAuthor2,
        name: "Mason Woodward",
        imgleft: img2left,
        imgright1: img2right1,
        imgright2: img2right2,
        imgright3: img2right3,
        imgright4: img1right4,
        imgtop: imgtop2,
        wishlist: "220",
        count: '26 item products'
    },
    {
        title: "Modern Art Collection",
        imgAuthor: imgAuthor3,
        name: "Freddie Carpenter",
        imgleft: img3left,
        imgright1: img3right1,
        imgright2: img3right2,
        imgright3: img3right3,
        imgright4: img2right4,
        imgtop: imgtop3,
        wishlist: "90",
        count: '26 item products'
    },
    {
        title: "Creative Art Collection",
        imgAuthor: imgAuthor1,
        name: "Ralph Garraway",
        imgleft: img4left,
        imgright1: img4right1,
        imgright2: img4right2,
        imgright3: img4right3,
        imgright4: img1right4,
        imgtop: imgtop1,
        wishlist: "145",
        count: '12 item products'
    },
    {
        title: "Colorful Abstract",
        imgAuthor: imgAuthor2,
        name: "Mason Woodward",
        imgleft: img5left,
        imgright1: img5right1,
        imgright2: img5right2,
        imgright3: img5right3,
        imgright4: img1right4,
        imgtop: imgtop2,
        wishlist: "100",
        count: '26 item products'
    },
    {
        title: "Modern Art Collection",
        imgAuthor: imgAuthor3,
        name: "Freddie Carpenter",
        imgleft: img6left,
        imgright1: img6right1,
        imgright2: img6right2,
        imgright3: img6right3,
        imgright4: img2right4,
        imgtop: imgtop3,
        wishlist: "220",
        count: '26 item products'
    },

]

export default popularCollectionData;