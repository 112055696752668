import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {login} from "../api/api.ts";

const Login = () => {

    const [show, setShow] = useState(false);
    const [tip, setTip] = useState('');
    const [form, setForm] = useState({});
    const navigate = useNavigate();

    const handleUsernameInputChange = (event) => {
        setForm({
            ...form,
            username: event.target.value
        });
    };

    const closeShow = () => setShow(false);

    const handlePasswordInputChange = (event) => {
        setForm({
            ...form,
            password: event.target.value
        });
    };

    const loginFun = (event) => {
        event.preventDefault(); // 阻止表单的默认提交行为
        login(form).then(res => {
            if (res.code === 200) {
                localStorage.setItem('token', res.msg);
                navigate('/');
            } else if (res.code === 4006) {
                setTip('Username or password is wrong, please check')
                setShow(true)
            }
        })
    }

    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Login</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Pages</Link></li>
                                    <li>Login</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-login tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="tf-title-heading ct style-1">
                                Login To NFTs
                            </h2>

                            <div className="flat-form box-login-social">
                                <div className="box-title-login">
                                    <h5>Login with social</h5>
                                </div>
                                <ul>
                                    <li>
                                        <Link to="#" className="sc-button style-2 fl-button pri-3">
                                            <i className="icon-fl-google-2"></i>
                                            <span>Google</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="sc-button style-2 fl-button pri-3">
                                            <i className="icon-fl-facebook"></i>
                                            <span>Facebook</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="flat-form box-login-email">
                                <div className="box-title-login">
                                    <h5>Or login with email</h5>
                                </div>

                                <div className="form-inner">
                                    <form id="contactform" onSubmit={loginFun}>
                                        <input id="name" name="name" tabIndex="1" value={form.username} onChange={handleUsernameInputChange} aria-required="true" required type="text" placeholder="Your username" />
                                        <input id="password" name="password" tabIndex="2" value={form.password} onChange={handlePasswordInputChange} aria-required="true" type="password" placeholder="Your password" required />
                                        <div className="row-form style-1">
                                            <label>Remember me
                                                <input type="checkbox" />
                                                <span className="btn-checkbox"></span>
                                            </label>
                                            <Link to="#" className="forgot-pass">Forgot Password ?</Link>
                                        </div>

                                        <button className="submit">Login</button>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <ToastContainer position="top-end" className="p-3 position-fixed" style={{ zIndex: 999 }}>
                <Toast show={show} onClose={closeShow} bg="danger" delay={3000} autohide>
                    <Toast.Header>
                        <h4 className="me-auto">Notification</h4>
                    </Toast.Header>
                    <Toast.Body>
                        <h5>{tip}</h5>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
}

export default Login;
