
import { extend } from 'umi-request';

export const REQUEST_URL = 'https://minyueshui.5mvp.com/travel';

const getUrl = () => {
  let url = 'https://minyueshui.5mvp.com/travel';
  // if (process.env.ROLE == 'hotel') {
  //   url = 'https://hotel-image-handle.oss-ap-southeast-5.aliyuncs.com/hotel/';
  // }
  return url;
};

export const IMG_URL = getUrl();
export const NO_IMG_URL = `https://hotel-image-handle.oss-ap-southeast-5.aliyuncs.com/hotel/base/photo.png?x-oss-process=style/compress`;

const errorHandler = (error: { response: Response }): Response => {
  const { response } = error;
  // if (response && response.status) {
  //   const errorText = codeMessage[response.status] || response.statusText;
  //   const { status, url } = response;
  //   notification.error({
  //     message: `Request Error ${status}: ${url}`,
  //     description: errorText,
  //   });
  // } else if (!response) {
  //   notification.error({
  //     description: 'Your network is abnormal, unable to connect to the server!',
  //     message: 'Network Error',
  //   });
  // }
  return response;
};

export const requestNotAuth = extend({
  errorHandler, // 默认错误处理
  credentials: 'same-origin', // 默认请求是否带上cookie
  prefix: `${REQUEST_URL}`,
});

const request = extend({
  errorHandler, // 默认错误处理
  credentials: 'same-origin', // 默认请求是否带上cookie
  prefix: `${REQUEST_URL}`,
});

const loginOut = () => {
  // loginout interface
  // await outLogin();
  // const { query = {}, pathname } = history.location;
  // const { redirect } = query;
  // Note: There may be security issues, please note
  localStorage.removeItem('user')
  localStorage.removeItem('token')
  if (window.location.pathname !== '/login') {
    window.location.href = 'http://localhost:3000/login';
  }
};

request.interceptors.response.use(async (response) => {
  const res = await response.clone().json();
  if (res.code === 401 || res.code === 4001) {
    loginOut();
    const token = localStorage.getItem('token');
    if (token) {
      message.destroy();
      message.warning('Login has expired, please login again.', 4);
      localStorage.removeItem('token');
    }
  } else if (res.code === 500) {
    message.error(res.msg);
  }
  return response;
});

request.interceptors.request.use(
  async (url, options, ...data) => {
    if (
      options.method === 'post' ||
      options.method === 'put' ||
      options.method === 'delete' ||
      options.method === 'get'
    ) {
      const token = localStorage.getItem('token');
      const headers = token
        ? {
            Authtoken: localStorage.getItem('token'),
        }
        : {};
      return {
        url,
        options: { ...options, headers },
      };
    }
  },
  { global: false },
);

export default request;
