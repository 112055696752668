import request from "../utils/request.ts";

export async function featuredList() {
  return request(`/fronted/featured/list`, {
    method: 'GET',
    data: {},
  });
}

export async function productDetail(id: string) {
  return request(`/fronted/detail/${id}`, {
    method: 'GET',
    data: {},
  });
}
///fronted/setting/info
export async function settingInfo() {
  return request(`/fronted/setting/info`, {
    method: 'GET',
    data: {},
  });
}
///user/order/insert
export async function creatOrderInfo(data: any) {
  return request(`/user/order/insert`, {
    method: 'Post',
    data: {
      ...data
    },
  });
}

export async function stripePaymentMethod(data: any) {
  return request(`/user/stripeEmbedPaymentMethod`, {
    method: 'Post',
    data: {
      ...data
    },
  });
}
// /login
export async function login(data: any) {
  return request(`/fronted/login`, {
    method: 'Post',
    data: {
      ...data
    },
  });
}

// /profile
export async function profile() {
  return request(`/system/user/profile`, {
    method: 'Get',
    data: {
    },
  });
}

// /profile
export async function updateProfile(data) {
  return request(`/system/user/profile/update`, {
    method: 'Put',
    data
  });
}

export async function search(data: any) {
  return request(`/fronted/search`, {
    method: 'Get',
    params: data
  });
}

// /user/order/list
export async function orderList(data: any) {
  return request(`/user/order/list`, {
    method: 'Post',
    data: {
      ...data
    }
  });
}

///order/query-booking-info/{id}
export async function queryBooking(id: string) {
  return request(`/user/order/query-booking-info/${id}`, {
    method: 'Get'
  });
}
//fronted/cms/list
export async function cmsList(data: any) {
  return request(`/fronted/cms/list`, {
    method: 'Get',
    params: data
  });
}
//  fronted/query/cms/

export async function cmsDetails(id: string) {
  return request(`/fronted/query/cms/${id}`, {
    method: 'Get'
  });
}
